import styled from "@emotion/styled"
import React from "react"
import { colors, fonts, device } from "./layout/GlobalStyles"
import { StaticImage } from "gatsby-plugin-image"
import { PanelContainer } from "./panels/Panel-Container"
import mainImage from "../images/purpose-hero.jpeg"

const StyledSubmission = styled.div`
  background: ${colors.secondary201};
  padding: 127px 0px;
  h1 {
    font-family: ${fonts.secondary};
    color: ${colors.primary2};
    font-size: 54px;
    font-weight: 800;
    letter-spacing: -1.88px;
    line-height: 60px;
  }
  p {
    font-size: 20px;
    font-weight: 400;
    letter-spacing: -0.69px;
    line-height: 36px;
  }
  @media ${device.tablet} {
    h1 {
      font-size: 60px;
      line-height: 65px;
    }
    p {
      font-size: 22px;
    }
  }
  @media ${device.laptop} {
    padding: 200px 0px;
    .text-container {
      max-width: 547px;
      margin-left: 210px;
    }
    h1 {
      font-size: 60px;
      line-height: 65px;
    }
    p {
      font-size: 22px;
    }
  }
`

export const Submission = () => {
  return (
    <StyledSubmission className="container">
      <div className="text-container">
        <h1>Thank you for your submission</h1>
        <p>
          We’re excited to share upcoming news, events, and a whole lot more
          with you. Stay tuned!{" "}
        </p>
      </div>
    </StyledSubmission>
  )
}
