import styled from "@emotion/styled"
import React from "react"
import { device } from "../layout/GlobalStyles"

const StyledPanelContainer = styled.section`
  /* regular ipad */
  @media ${device.tablet} and (max-width: 1020px) and (orientation: portrait) {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
  @media ${device.tablet} and (orientation: landscape) {
    display: flex;
    justify-content: space-between;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
  @media ${device.laptop} {
    display: flex;
    justify-content: space-between;
    flex-direction: ${({ reverse }) => (reverse ? "row-reverse" : "row")};
  }
`

export const PanelContainer = ({ children, reverse }) => {
  return (
    <StyledPanelContainer className="container" reverse={reverse}>
      {children}
    </StyledPanelContainer>
  )
}
