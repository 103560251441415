import { Submission } from "../components/Submission"
import styled from "@emotion/styled"
import React from "react"
import { colors, fonts, device } from "../components/layout/GlobalStyles"
import { DefaultLayout } from "../layout/DefaultLayout"
import { siteContext } from "../layout/SiteContextProvider"
import { Seo } from "../components/Seo"

const PageContainer = styled.div`
  background: ${colors.secondary201};
`

const Success = ({ data }) => {
  return (
    <PageContainer>
      <Seo
        title="Thank you for subscribing - MLIFE Foundation"
        description="We’re excited to share upcoming news, events, and a whole lot more with you. Stay tuned!"
      />
      <Submission />
    </PageContainer>
  )
}

export default Success
